import Vue from "vue";
import Vuetify, { UserVuetifyPreset } from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const opts: UserVuetifyPreset = {
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: {
        primary: "#424B5A",
        secondary: "#FFB900",
        normal: "#C2D1D9",
        accent: "#F8F9FA"
      }
    }
  }
};

export default new Vuetify(opts);
