import AuthState, { AuthStatus } from "@/store/auth/auth.state";

export enum AuthMutationsNames {
  VET_LOGIN_LOADING = "login-loading",
  VET_LOGIN_SUCCESS = "login-success",
  VET_LOGIN_ERROR = "login-error",
  VET_LOGOUT = "logout",
  VET_SET_PERMISSIONS = "set_permissions"
}

const AuthMutations = {
  [AuthMutationsNames.VET_LOGIN_LOADING]: (state: AuthState) => {
    state.authStatus = AuthStatus.loading;
  },
  [AuthMutationsNames.VET_LOGIN_SUCCESS]: (state: AuthState, authData: any) => {
    state.authStatus = AuthStatus.success;
    state.authToken = authData.token;
    state.vetName = authData.vetName;
    state.practiceGroupName = authData.practiceGroupName;
  },
  [AuthMutationsNames.VET_LOGIN_ERROR]: (state: AuthState, response: any) => {
    state.authStatus = AuthStatus.error;
    state.errorMessage = response.errorMessage;
  },
  [AuthMutationsNames.VET_LOGOUT]: (state: AuthState) => {
    state.authStatus = AuthStatus.none;
    state.authToken = "";
    state.role = null;
    state.accesses = null;
  },
  [AuthMutationsNames.VET_SET_PERMISSIONS]: (state: AuthState, permissionData: any) => {
    state.role = permissionData.role;
    state.accesses = permissionData.accesses;
  }
};

export default AuthMutations;
