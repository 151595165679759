import { PetAge, PetDetailsResponsePayload } from "@/api/api.dto";
import { PetDetails } from "@/api/design_data/pet_details.data";
import ConditionRecord from "@/models/condition-record.model";

type DateOfBirth = {
  year: number;
  month: number;
  day: number;
};

export enum Gender {
  MALE = "male",
  FEMALE = "female"
}

export type HealthIssues = {
  displayName: string;
  healthIssueId: number;
  nameDefault: string;
}[];

export type PetDetails = {
  activityLevel: {
    identifier: string;
    nameDefault: string;
  };
  age: string;
  breed: {
    id: number;
    name: string;
  };
  condition: ConditionRecord;
  dateOfBirth: DateOfBirth;
  displayDateOfBirth: string;
  dietType?: string;
  gender: Gender;
  healthIssue: HealthIssues;
  neutered: boolean;
  name: string;
  petId?: number;
  weight: number;
  petStatus: string;
  subscriptionStatus: string;
  subscriptionId: number;
  customerId: number;
  size: string;
  currentLifeStage: string;
  nextLifeStage: string;
  nextLifeStageTransitionDate: Date | null;
  vetPracticeId: number;
  petTypeId: number;
};

export function mapAgeToText(age: PetAge): string {
  if (age.years === 0 && age.months === 0) {
    return age.days === 1 ? "1 day" : `${age.days} days`;
  }
  let ageText = "";
  if (age.years === 1) {
    ageText += "1 year";
  }
  if (age.years > 1) {
    ageText += `${age.years} years`;
  }
  if (age.months === 1) {
    ageText += ageText ? " and 1 month" : "1 month";
  }
  if (age.months > 1) {
    ageText += ageText ? ` and ${age.months} months` : `${age.months} months`;
  }
  return ageText;
}

function formatDateForBirth(dateOfBirth: string): string {
  const date = new Date(dateOfBirth);
  return date ? date.toLocaleDateString("en-GB") : "";
}

function mapDateOfBirth(dateOfBirth: string): DateOfBirth {
  const dateParts = dateOfBirth.split("-");
  return {
    year: Number(dateParts[0]),
    month: Number(dateParts[1]),
    day: Number(dateParts[2])
  } as DateOfBirth;
}

export function mapPetDetailsResponseToViewModel(
  petDetailsResponse: PetDetailsResponsePayload
): PetDetails {
  const petDetails = {
    activityLevel: {
      identifier: petDetailsResponse.activity_level
        ? petDetailsResponse.activity_level.identifier
        : null,
      nameDefault: petDetailsResponse.activity_level
        ? petDetailsResponse.activity_level.name_default
        : null
    },
    breed: {
      id: petDetailsResponse.breed ? petDetailsResponse.breed.breed_id : null,
      name: petDetailsResponse.breed ? petDetailsResponse.breed.name : null
    },
    condition: new ConditionRecord(undefined, petDetailsResponse.condition),
    dateOfBirth: petDetailsResponse.date_of_birth
      ? mapDateOfBirth(petDetailsResponse.date_of_birth)
      : null,
    displayDateOfBirth: formatDateForBirth(petDetailsResponse.date_of_birth),
    gender: petDetailsResponse.gender as Gender,
    neutered: petDetailsResponse.is_neutered,
    name: petDetailsResponse.name,
    weight: petDetailsResponse.weight,
    petId: petDetailsResponse.pet_id,
    vetPracticeId: petDetailsResponse.vet_practice_id,
    petTypeId: petDetailsResponse.pet_type_id
  } as PetDetails;
  if (petDetailsResponse.health_condition) {
    petDetails.healthIssue = petDetailsResponse.health_condition.map(healthIssue => ({
      displayName: healthIssue.display_name_default,
      healthIssueId: healthIssue.health_issue_id,
      nameDefault: healthIssue.name_default
    }));
  }
  if (petDetailsResponse.diet_type) {
    petDetails.dietType = petDetailsResponse.diet_type;
  }
  petDetails.age = mapAgeToText(petDetailsResponse.age);
  petDetails.petStatus = petDetailsResponse.status;
  petDetails.subscriptionStatus = petDetailsResponse.subscription_status;
  petDetails.subscriptionId = petDetailsResponse.subscription_id;
  petDetails.customerId = petDetailsResponse.customer_id;
  petDetails.size = petDetailsResponse.size || "n/a";
  petDetails.currentLifeStage = petDetailsResponse.current_life_stage || "n/a";
  petDetails.nextLifeStage = petDetailsResponse.next_life_stage || "n/a";
  petDetails.nextLifeStageTransitionDate = petDetailsResponse.next_life_stage_transition_date
    ? new Date(petDetailsResponse.next_life_stage_transition_date)
    : null;
  return petDetails;
}

export function mgToKg(mg: number): number {
  return mg / 1000;
}
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
function nthDay(day: number) {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function nthDayDateDisplay(date: Date) {
  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  return `${day}${nthDay(day)} ${month} ${year}`;
}
