import Vue from "vue";
import VueGtm from "vue-gtm";
import { LocalStorageProduction } from "@/services/localStorage/localStorage.production";
import ApiProduction from "@/api/api.production";
import axios from "axios";
import vuetify from "@/plugins/vuetify";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import { AuthActionsNames } from "@/store/auth/auth.actions";
import { capitalize } from "@/utils/capitalize.function";
import round from "@/utils/round.function";
import { mapAgeToText, mgToKg, nthDayDateDisplay } from "@/components/common.vm";
import { FeatureFlags } from "@/api/featureFlags/featureFlags.api";
import { FeatureFlagActionNames } from "@/store/feature-flag/feature-flag.actions";
import { formatDiscount, formatPrice } from "@/utils/currency";
import App from "./App.vue";
import router from "./router";
import { RouteNames, RouteNamesV2 } from "./router/routeNames";

import store from "./store";
// import Stripe on site loading to leverage anti-fraud functionality
import "@stripe/stripe-js";
// import local fonts here to avoid css-loader errors
import "@/assets/scss/typography/custom-fonts.scss";

Vue.config.productionTip = false;
export const globalMixin = {
  data() {
    return {
      get routeNames() {
        return RouteNames;
      },
      get prototypeRouteNames() {
        return RouteNamesV2;
      }
    };
  },
  filters: {
    capitalize: capitalize,
    round: round,
    mapAgeToText: mapAgeToText,
    mgToKg: mgToKg,
    nthDayDateDisplay: nthDayDateDisplay,
    formatPrice: formatPrice,
    formatDiscount: formatDiscount
  }
};
Vue.mixin(globalMixin);

const token = localStorage.getItem("apitoken");
if (token) {
  axios.defaults.headers.common.Authorization = token;
}

Vue.use(VueGtm, {
  id: "GTM-TXPPQ6N",
  vueRouter: router
});

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

const loadFeatureFlagToStore = (flagName: string, action: FeatureFlagActionNames): Promise<any> => {
  return FeatureFlags.isEnabled(flagName).then(state => {
    return store.dispatch(action, state);
  });
};

const loadFeatureFlags = () => {
  // Add required feature flags here
  return Promise.all([
    loadFeatureFlagToStore("v2p:zendesk-sso", FeatureFlagActionNames.SET_ZENDESK_SSO),
    loadFeatureFlagToStore("v2p:vet-prototype", FeatureFlagActionNames.SET_VET_PROTOTYPE),
    loadFeatureFlagToStore("v2p:vet-vuetify-views", FeatureFlagActionNames.SET_VET_VUETIFY_VIEWS)
  ]);
};

const loadPermissions = () => {
  if (store.getters.isAuthenticated) {
    return store.dispatch(AuthActionsNames.LOAD_PERMISSIONS);
  }
  return Promise.resolve();
};

Promise.all([loadFeatureFlags(), loadPermissions()]).finally(() => {
  new Vue({
    router,
    store,
    vuetify,
    provide: {
      Api: new ApiProduction(),
      LocalStorage: new LocalStorageProduction()
    },
    render: h => h(App)
  }).$mount("#app");
});
