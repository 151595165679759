import store from "@/store";

export function hasAccess(viewName: string | null | undefined): boolean {
  const restrictedViews = store.getters.restrictedViews;
  const accesses = store.getters.accesses;
  const userRole = store.getters.role;
  if (restrictedViews.indexOf(viewName) > -1) {
    return userRole in accesses && accesses[userRole].indexOf(viewName) > -1;
  }
  return true;
}
