import AuthMutations from "@/store/auth/auth.mutations";
import AuthActions, {
  AUTH_TOKEN_KEY,
  VET_NAME,
  VET_PRACTICE_GROUP_NAME
} from "@/store/auth/auth.actions";
import AuthGetters from "@/store/auth/auth.getters";
import AuthState, { AuthStatus } from "@/store/auth/auth.state";

const AuthStore = {
  state: {
    vetName: localStorage.getItem(VET_NAME) || "",
    authToken: localStorage.getItem(AUTH_TOKEN_KEY) || "",
    practiceGroupName: localStorage.getItem(VET_PRACTICE_GROUP_NAME) || "",
    role: null,
    authStatus: AuthStatus.none,
    errorMessage: "",
    accesses: null
  } as AuthState,
  getters: AuthGetters,
  mutations: AuthMutations,
  actions: AuthActions
};

export default AuthStore;
