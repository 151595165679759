import { Commit, Dispatch } from "vuex";
import { VetLoginResponse } from "@/api/api.dto";
import axios from "axios";
import ApiProduction from "@/api/api.production";
import { AuthMutationsNames } from "@/store/auth/auth.mutations";
import permissionService from "@/services/permission.service";

const api = new ApiProduction();

export const AUTH_TOKEN_KEY = "apitoken";
export const VET_PRACTICE_GROUP_NAME = "vet_practice_group_name";
export const VET_NAME = "vet_name";

export interface VuexCommit {
  commit: Commit;
  dispatch: Dispatch;
}

export enum AuthActionsNames {
  VET_LOGIN = "VET_LOGIN",
  VET_LOGOUT = "VET_LOGOUT",
  LOAD_PERMISSIONS = "LOAD_PERMISSIONS"
}

const AuthActions = {
  [AuthActionsNames.VET_LOGIN]: ({ commit, dispatch }: VuexCommit, { username, password }: any) =>
    /* eslint-disable */
    new Promise(async (resolve, reject) => {
      // The Promise used for router redirect in login
      commit(AuthMutationsNames.VET_LOGIN_LOADING);
      const resp: VetLoginResponse = await api.vetLogin(username, password);
      if (resp.success) {
        axios.defaults.headers.common.Authorization = resp.apitoken;

        await dispatch(AuthActionsNames.LOAD_PERMISSIONS);
        commit(AuthMutationsNames.VET_LOGIN_SUCCESS, {
          token: resp.apitoken,
          vetName: resp.vet_name,
          practiceGroupName: resp.vet_practice_group_name
        });
        localStorage.setItem(AUTH_TOKEN_KEY, resp.apitoken);
        localStorage.setItem(VET_NAME, resp.vet_name);
        localStorage.setItem(VET_PRACTICE_GROUP_NAME, resp.vet_practice_group_name);
        resolve();
      } else {
        commit(AuthMutationsNames.VET_LOGIN_ERROR, resp);
        reject();
      }
      /* eslint-enable */
    }),
  [AuthActionsNames.VET_LOGOUT]: ({ commit }: VuexCommit) =>
    /* eslint-disable */
    new Promise(async (resolve, reject) => {
      try {
        delete axios.defaults.headers.common.Authorization;
        localStorage.removeItem(AUTH_TOKEN_KEY);
        localStorage.removeItem(VET_NAME);
        localStorage.removeItem(VET_PRACTICE_GROUP_NAME);
        resolve("Successfully logged out");
        commit(AuthMutationsNames.VET_LOGOUT);
      } catch (error) {
        reject(error);
      }
      /* eslint-enable */
    }),
  [AuthActionsNames.LOAD_PERMISSIONS]: ({ commit }: VuexCommit) =>
    /* eslint-disable */
    new Promise(async (resolve, reject) => {
      try {
        const permission = await permissionService.getPermission();
        commit(AuthMutationsNames.VET_SET_PERMISSIONS, {
          role: permission.role,
          accesses: permission.accesses
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    })
  /* eslint-enable */
};

export default AuthActions;
