export enum RouteNames {
  LOGIN = "login",
  DASHBOARD = "dashboard",
  PRE_CUSTOMER_DETAILS = "pre-customer-details",
  EDIT_CUSTOMER_DETAILS = "edit-customer-details",
  PET_INFORMATION = "pet-information",
  URGENT_DIET = "urgent-diet",
  DIET_REQUIREMENTS = "diet-requirements",
  NUTRITIONAL_PLAN = "nutritional-plan",
  PORTIONING = "portioning",
  CUSTOMER_DETAILS = "customer-details",
  PAYMENT_DETAILS = "payment-details",
  THANK_YOU = "thank-you",
  PASSWORD_RESET = "password-reset",
  REQUEST_PASSWORD_RESET = "request-password-reset",
  CUSTOMER_HUB = "customer-hub",
  PRICE = "price",
  PERFORMANCE_DASHBOARD = "performance-dashboard",
  FEEDING_PREFERENCES = "feeding-preferences",
  CONDITION_HISTORY = "condition-history",
  ZENDESK_SSO = "zendesk-sso",
  FEEDING_PLAN = "feeding-plan",
  FORTIFLORA = "fortiflora",
  CHECKOUT = "checkout",
  PAYMENT = "payment",
  PAYMENT_THANK_YOU = "payment-thank-you"
}

export enum RouteNamesV2 {
  ROOT = "v2",
  CREATE_PET = "v2.sign-up",
  THANK_YOU = "v2.thank-you",
  CREATE_CUSTOMER = "v2.create-customer",
  EMAIL_EXISTS = "v2.email-exists",
  PET_SELECTION = "v2.pet-selection",
  BLEND_CONFIGURATION = "v2.blend-configuration"
}
