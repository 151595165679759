
import Vue from "vue";
import {
  CustomerFormData,
  mapToCreateCustomerRequest
} from "@/components/PreCustomerDetails/PreCustomerDetails.vm";
import { ApiInterface } from "@/api/api.interface";
import ChoiceSelector from "@/components/ChoiceSelector.vue";

import { CreateCustomerResponse, CustomerType } from "@/api/api.dto";
import LegalDisclaimer from "@/components/LegalDisclaimer/LegalDisclaimer.vue";
import { RouteNames } from "@/router/routeNames";

export type ApiErrors = {
  getCustomerData: string;
};
type Data = {
  customerFormData: CustomerFormData;
  saveCustomerDataResponse: string;
  apiErrors: ApiErrors;
  nextRoute: string;
  customerTypes: Array<CustomerType>;
};
type Inject = {
  Api: ApiInterface;
};
type Methods = {
  handleSubmit(): Promise<void>;
  loadCustomerData(): void;
  saveCustomer(): Promise<void>;
  updateCustomer(): Promise<void>;
  clearAllErrors(): void;
  customerIdExists(): boolean;
  goToDashboard(): void;
  continueButtonSubmit(): void;
  dashboardButtonSubmit(): void;
};
export default Vue.extend<Data, any, {}, any & Inject>({
  name: "PreCustomerDetails",
  inject: ["Api"],
  components: { LegalDisclaimer, ChoiceSelector },
  data() {
    return {
      submitDisabled: false,
      errors: null,
      customerTypes: [],
      customerFormData: {
        customerId: null,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        petHealthClubMember: false,
        dataCaptureConsent: false,
        contactConsent: null
      },
      saveCustomerDataResponse: "",
      apiErrors: {
        getCustomerData: ""
      } as ApiErrors,
      nextRoute: RouteNames.PET_INFORMATION
    };
  },
  filters: {
    errorField: (value: string) => {
      if (value === "customer_type_id") {
        return "select type of owner";
      }
      return value.replace("_", " ");
    }
  },
  async mounted() {
    if (this.petIdExists()) {
      await this.loadCustomerData();
    }
  },
  methods: {
    isEmpty(obj: any) {
      if (obj === null) return true;
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    isInvalid(field: string) {
      try {
        return this.errors[field].length > 0;
      } catch (e) {
        return false;
      }
    },
    petIdExists() {
      return !!this.$route.params.pet_id;
    },
    getPetId() {
      return this.$route.params.pet_id;
    },
    async continueButtonSubmit() {
      this.nextRoute = this.routeNames.CUSTOMER_HUB;
      await this.handleSubmit();
    },
    async dashboardButtonSubmit() {
      this.nextRoute = "dashboard";
      await this.handleSubmit();
    },
    async handleSubmit() {
      const form = this.$refs.form;
      this.submitDisabled = true;
      if (form.checkValidity()) {
        if (this.petIdExists()) {
          await this.updateCustomer();
        } else {
          await this.saveCustomer();
        }
      } else {
        form.reportValidity();
        this.submitDisabled = false;
      }
    },
    async updateCustomer() {
      const customerId = this.customerFormData.customerId;
      const response = await this.Api.updatePreCustomer(
        mapToCreateCustomerRequest(this.customerFormData),
        customerId
      );
      if (response.success) {
        this.clearAllErrors();
        // Navigate to next page
        await this.$router.push({
          name: this.nextRoute,
          params: { customer_id: `${response.customer_id}` }
        });
      } else {
        this.saveCustomerDataResponse = "Couldn't save update customer details.";
        this.submitDisabled = false;
      }
    },
    async saveCustomer() {
      const response: CreateCustomerResponse = await this.Api.createCustomer(
        mapToCreateCustomerRequest(this.customerFormData)
      );
      if (response.success) {
        this.clearAllErrors();
        // Navigate to next page
        await this.$router.push({
          name: this.nextRoute,
          params: { customer_id: `${response.customer_id}` }
        });
      } else {
        this.submitDisabled = false;
        if (response.errors) this.errors = response.errors;
      }
    },
    async loadCustomerData() {
      const petId = this.getPetId();
      if (petId) {
        this.apiErrors.getCustomerData = "";
        const customerResponse = await this.Api.getCustomerDetails(petId);
        if (customerResponse.success && customerResponse.customer) {
          this.customerFormData.customerId = customerResponse.customer.customer_id;
          this.customerFormData.firstName = customerResponse.customer.first;
          this.customerFormData.lastName = customerResponse.customer.last;
          this.customerFormData.phoneNumber = customerResponse.customer.mobile_phone;
          this.customerFormData.email = customerResponse.customer.email;
          this.customerFormData.dataCaptureConsent = customerResponse.customer.data_capture_consent;
          this.customerFormData.contactConsent = customerResponse.customer.contact_consent
            ? "no"
            : "yes";
          this.customerFormData.petHealthClubMember =
            customerResponse.customer.pet_health_club_member;
        } else {
          this.apiErrors.getCustomerData = "Could not fetch customer data.";
        }
      }
    },
    clearAllErrors() {
      this.saveCustomerDataResponse = "";
      this.apiErrors.getCustomerData = "";
    },
    goToDashboard() {
      this.$router.push({ name: "dashboard" });
    }
  }
});
