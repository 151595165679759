import FeatureFlagState from "@/store/feature-flag/feature-flag.state";

const FeatureFlagGetters = {
  zendeskSSOFlow(state: FeatureFlagState) {
    return state.zendeskFlag;
  },
  vetPrototype(state: FeatureFlagState) {
    return state.vetPrototypeFlag;
  },
  vetVuetifyViews(state: FeatureFlagState) {
    return state.vetVuetifyViewsFlag;
  }
};

export default FeatureFlagGetters;
