export type SearchResultData = {
  pet_id: number;
  name: string;
  customer_email: string;
  customer_name: string;
  customer_phone: string;
  last_modified: number;
  status: string;
};

export type DashboardAction = {
  actionName: string;
  displayText: string;
  nextRoute: string;
  cssClass: string;
  cypressIdentifier?: string;
};

export type DashboardActionOptions = {
  petId: number;
  customerId: number;
};

export type SubscriptionStatusVM = {
  displayStatus: string;
  actions: DashboardAction[];
};

export enum ConsultationModes {
  NONE = "none",
  VIEW_OR_EDIT_ACTIVE = "view-or-edit-active"
}

export const DASHBOARD_ACTIONS = {
  EDIT_INCOMPLETE: "edit-incomplete",
  VIEW_OR_EDIT_ACTIVE: "view-or-edit-active",
  CHECKOUT_COMPLETE: "checkout-complete",
  CREATE_NEW: "create-new",
  SEE_CUSTOMER_DETAILS: "see-customer-details",
  REACTIVATE_SUBSCRIPTION: "reactivate-subscription"
};

export const EDIT_INCOMPLETE: DashboardAction = {
  actionName: DASHBOARD_ACTIONS.EDIT_INCOMPLETE,
  displayText: "Edit",
  nextRoute: "v2.blend-configuration",
  cssClass: ""
};

export const CHECKOUT_COMPLETE: DashboardAction = {
  actionName: DASHBOARD_ACTIONS.CHECKOUT_COMPLETE,
  displayText: "Checkout",
  nextRoute: "nutritional-plan",
  cssClass: ""
};

export const EDIT_ACTIVE: DashboardAction = {
  actionName: DASHBOARD_ACTIONS.VIEW_OR_EDIT_ACTIVE,
  displayText: "View/Edit plan",
  nextRoute: "nutritional-plan",
  cssClass: "",
  cypressIdentifier: "view-edit-plan"
};

export const CREATE_NEW: DashboardAction = {
  actionName: DASHBOARD_ACTIONS.CREATE_NEW,
  displayText: "+ Create new pet",
  nextRoute: "pre-customer-details",
  cssClass: ""
};

export const SEE_CUSTOMER_DETAILS: DashboardAction = {
  actionName: DASHBOARD_ACTIONS.SEE_CUSTOMER_DETAILS,
  displayText: "Customer details",
  nextRoute: "customer-details",
  cssClass: "brand-button-secondary"
};

export const REACTIVATE_SUBSCRIPTION: DashboardAction = {
  actionName: DASHBOARD_ACTIONS.REACTIVATE_SUBSCRIPTION,
  displayText: "Reactivate",
  nextRoute: "v2.blend-configuration",
  cssClass: ""
};
