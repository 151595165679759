import AuthState from "@/store/auth/auth.state";

const AuthGetters = {
  isAuthenticated: (state: AuthState) => !!state.authToken,
  authStatus: (state: AuthState) => state.authStatus,
  vetName: (state: AuthState) => state.vetName,
  vetPracticeGroupName: (state: AuthState) => state.practiceGroupName,
  errorMessage: (state: AuthState) => state.errorMessage,
  role: (state: AuthState) => state.role,
  restrictedViews(state: AuthState) {
    let restrictedViews: Array<string> = [];
    if (state.accesses) {
      Object.entries(state.accesses).forEach(([, value]) => {
        restrictedViews = restrictedViews.concat(value);
      });
    }
    return restrictedViews;
  },
  accesses: (state: AuthState) => {
    return state.accesses;
  }
};

export default AuthGetters;
