<script>
import Vue from "vue";

export default Vue.extend({
  name: "ChoiceSelector",
  props: ["value", "groupName", "leftValue", "leftDisplay", "rightValue", "rightDisplay"],
  computed: {
    selectorValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
});
</script>

<template>
  <div class="btn btn-group p-0">
    <input
      class="BrandSquaredToggle--input d-none"
      type="radio"
      :name="groupName"
      :id="leftValue ? leftValue : 'left'"
      :value="leftValue"
      v-model="selectorValue"
    />
    <label class="btn BrandSquaredToggle--label" :for="leftValue ? leftValue : 'left'">
      <b>{{ leftDisplay }}</b>
    </label>
    <input
      class="BrandSquaredToggle--input d-none"
      type="radio"
      :name="groupName"
      :id="rightValue ? rightValue : 'right'"
      :value="rightValue"
      v-model="selectorValue"
    />
    <label class="btn BrandSquaredToggle--label" :for="rightValue ? rightValue : 'right'">
      <b>{{ rightDisplay }}</b>
    </label>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/scss/base_styles/variables.scss";

.BrandSquaredToggle--input {
  visibility: hidden;
}

.BrandSquaredToggle--label {
  min-width: 120px;
  height: 40px;
  cursor: pointer;
  color: $brand-gray-8;
  background: rgba(194, 209, 217, 0.35);
  box-shadow: 0 2px 4px 0 $brand-gray-lightest;
  border-radius: 3px;
}

.BrandSquaredToggle--input:checked + .BrandSquaredToggle--label {
  background: white;
  color: $brand-yellow;
}
</style>
