export interface AccessInterface {
  [id: string]: Array<string>;
}

export default class Permission {
  role: string;
  accesses?: AccessInterface = {};

  constructor(init?: Partial<Permission>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        role: rawData.role,
        accesses: rawData.accesses
      });
    }
  }
}
