import axios from "axios";
import Permission from "@/models/permission.model";

export class PermissionService {
  private readonly baseUrl = "/api/vets/v1/permissions";

  public getPermission(): Promise<Permission> {
    return axios.get(this.baseUrl).then(response => {
      return new Permission({}, response.data.resp);
    });
  }
}

const permissionService = new PermissionService();
export default permissionService;
