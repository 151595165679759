<script>
import Vue from "vue";
import { AuthActionsNames } from "@/store/auth/auth.actions";
import axios from "axios";
import authorizationService from "@/services/authorization.service";
import { AUTH_INTERVAL_TIME_CHECK } from "@/settings";
import ZendeskService from "@/services/zendesk.service";
import { mapGetters } from "vuex";
import { RouteNamesV2 } from "@/router/routeNames";

function shouldGoToLogin(route_name) {
  return route_name !== "login" && !window.location.hash.startsWith("#/password-reset?token="); // can't use route because at that moment routes are not setup yet.
}

export default Vue.extend({
  name: "app",
  inject: ["Api"],
  methods: {
    createAuthorizationCheckerInterval() {
      setInterval(() => {
        if (!this.isAuthenticated) {
          return;
        }
        authorizationService.getProfile();
      }, AUTH_INTERVAL_TIME_CHECK * 1000);
    },
    updateZendeskToken() {
      setInterval(() => {
        ZendeskService.setup();
      }, 3 * 60 * 1000);
    }
  },
  computed: {
    ...mapGetters(["zendeskSSOFlow", "isAuthenticated"])
  },
  async created() {
    axios.interceptors.response.use(
      undefined,
      err =>
        new Promise(() => {
          if (err.response.status === 401) {
            if (err.response.data.resp.preregistration) {
              this.$router.push({
                name: RouteNamesV2.CREATE_CUSTOMER,
                query: { error: "wrongMagicLink" }
              });
              return;
            }
            this.$store.dispatch(AuthActionsNames.VET_LOGOUT);
            if (shouldGoToLogin(this.$route.name)) {
              this.$router.push({ name: "login" });
              return;
            }
          }
          throw err;
        })
    );
    this.createAuthorizationCheckerInterval();
    if (this.zendeskSSOFlow && this.isAuthenticated) {
      await ZendeskService.setup(); // initial token setup
      this.updateZendeskToken();
    } else if (this.isAuthenticated) {
      await ZendeskService.getZendeskJwtToken(); // pull once a token to create a feature flag
    }
  }
});
</script>

<template>
  <router-view />
</template>

<style lang="scss">
@import "@/assets/scss/main.scss";
.v-application > * {
  font-family: Inter, sans-serif;
}
</style>
