import CustomerState from "@/store/customer/customer.state";

export enum CustomerMutationsNames {
  SET_CUSTOMER = "SET_CUSTOMER"
}

const CustomerMutations = {
  [CustomerMutationsNames.SET_CUSTOMER](state: CustomerState, customer: any): void {
    state.customer = customer;
  }
};

export default CustomerMutations;
