<script>
import Vue from "vue";
import { AuthActionsNames } from "@/store/auth/auth.actions";
import { hasAccess } from "@/utils/has-access.function";
import Cookies from "js-cookie";
import ZendeskService, { ZendeskCookieID } from "@/services/zendesk.service";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "WithOldVetBar",
  inject: ["Api"],
  methods: {
    async logout(event) {
      event.preventDefault();
      try {
        await this.Api.vetLogout();
        this.$store.dispatch(AuthActionsNames.VET_LOGOUT);
        this.dispatchGTMEventLogout();
        ZendeskService.destroy();
        this.$router.push("/login");
      } catch (error) {
        // handle error
      }
    },
    dispatchGTMEventLogout() {
      this.$gtm.trackEvent({
        event: "vet_logout"
      });
    },
    hasAccess
  },
  computed: {
    ...mapGetters(["zendeskSSOFlow"]),
    shouldHideNav() {
      return !this.$store.getters.isAuthenticated;
    },
    getVetPractice() {
      return this.$store.getters.vetPracticeGroupName;
    },
    getVetName() {
      return this.$store.getters.vetName;
    },
    zendeskLink() {
      const zendeskJwtToken = Cookies.get(ZendeskCookieID);
      return `https://personalisedproplan.zendesk.com/hc?jwt=${zendeskJwtToken}`;
    }
  }
});
</script>

<template>
  <div class="container">
    <div class="row bg-light mb-4 align-items-center" :hidden="shouldHideNav">
      <div class="col-auto p-0">
        <router-link
          :to="{ name: 'dashboard' }"
          class="navbar-brand navbar-title mr-auto text-wrap p-0"
          data-heap="nav-bar-logo-click"
          ><img
            height="100"
            src="https://vet2pet-prod-main-store.s3.eu-west-1.amazonaws.com/assets/logo-bg-black.png"
            alt="PRO PLAN Personalised Nutrition"
          />
        </router-link>
      </div>
      <div class="ml-auto text-right mt-2 col-auto col-md-3">
        <span data-heap="nav-bar-vet-name" class="font-weight-bold data-hj-suppress">{{
          getVetName
        }}</span
        ><br />
        <router-link
          v-if="hasAccess('performance-dashboard')"
          :to="{ name: 'performance-dashboard' }"
          class="font-weight-bolder text-warning mr-auto"
        >
          <span data-heap="nav-bar-vet-practice" class="font-weight-light">{{
            getVetPractice
          }}</span>
        </router-link>
        <span v-else data-heap="nav-bar-vet-practice" class="font-weight-light">{{
          getVetPractice
        }}</span>
      </div>
      <div class="d-none d-lg-flex">
        <div class="col-auto mt-2 text-right" v-if="zendeskSSOFlow">
          <a :href="zendeskLink" target="_blank" class="btn btn-outline-info"
            ><span class="mdi mdi-help-circle-outline"></span> Help Centre</a
          >
        </div>
        <div class="col-auto text-right align-self-center">
          <a
            href="#"
            class="btn brand-button"
            style="min-width: 100px"
            @click="logout"
            data-heap="nav-bar-logout"
            >Log out</a
          >
        </div>
      </div>
      <div class="d-flex d-lg-none">
        <div class="col-auto text-right align-self-center">
          <div class="row" v-if="zendeskSSOFlow">
            <div class="col pb-1">
              <a :href="zendeskLink" target="_blank" class="btn btn-outline-info w-100"
                ><span class="mdi mdi-help-circle-outline"></span> Help Centre</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col">
              <a
                href="#"
                class="btn brand-button w-100"
                style="min-width: 100px"
                @click="logout"
                data-heap="nav-bar-logout"
                >Log out</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<style lang="scss">
@import "../assets/scss/main.scss";

.navbar-title {
  color: $brand-gray-10;
  font-weight: 700;

  &:hover {
    color: $brand-gray-9;
  }
}
</style>
