export default class ConditionRecord {
  conditionId?: number;
  identifier?: string;
  name?: string;

  public constructor(init?: Partial<ConditionRecord>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        conditionId: rawData.condition_id,
        identifier: rawData.identifier,
        name: rawData.name_default
      });
    }
  }

  public getName(): string {
    return `${this.conditionId ?? ""} - ${this.name ?? ""}`;
  }
}
