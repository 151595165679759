import { Pet } from "@/models/pet.model";
import PetState from "@/store/pet/pet.state";

export enum PetMutationsNames {
  SET_PET = "SET_PET"
}

const PetMutations = {
  [PetMutationsNames.SET_PET](state: PetState, pet: Pet): void {
    state.pet = pet;
  }
};

export default PetMutations;
