import { Commit } from "vuex";
import { FeatureFlagMutationsNames } from "@/store/feature-flag/feature-flag.mutations";

export interface VuexCommit {
  commit: Commit;
}

export enum FeatureFlagActionNames {
  SET_ZENDESK_SSO = "SET_ZENDESK_SSO",
  SET_VET_PROTOTYPE = "SET_VET_PROTOTYPE",
  SET_VET_VUETIFY_VIEWS = "SET_VET_VUETIFY_VIEWS"
}

const FeatureFlagActions = {
  [FeatureFlagActionNames.SET_ZENDESK_SSO]: ({ commit }: VuexCommit, zendeskFlag: any) => {
    commit(FeatureFlagMutationsNames.ZENDESK_SSO_FLAG, zendeskFlag);
  },
  [FeatureFlagActionNames.SET_VET_PROTOTYPE]: ({ commit }: VuexCommit, vetPrototypeFlag: any) => {
    commit(FeatureFlagMutationsNames.VET_PROTOTYPE_FLAG, vetPrototypeFlag);
  },
  [FeatureFlagActionNames.SET_VET_VUETIFY_VIEWS]: (
    { commit }: VuexCommit,
    vetVuetifyViewsFlag: any
  ) => {
    commit(FeatureFlagMutationsNames.VET_VUETIFY_VIEWS, vetVuetifyViewsFlag);
  }
};

export default FeatureFlagActions;
