import axios from "axios";

export class AuthorizationService {
  protected readonly baseUrl = "/api/vets/v1/";

  async getProfile(): Promise<any> {
    return axios.get(`${this.baseUrl}profile`).then(response => response);
  }
}

const authorizationService = new AuthorizationService();
export default authorizationService;
