import { Commit } from "vuex";
import { CustomerMutationsNames } from "@/store/customer/customer.mutations";

export interface VuexCommit {
  commit: Commit;
}

export enum CustomerActionsNames {
  SET_CUSTOMER = "SET_CUSTOMER"
}

const CustomerActions = {
  [CustomerActionsNames.SET_CUSTOMER]: ({ commit }: VuexCommit, customer: any) => {
    commit(CustomerMutationsNames.SET_CUSTOMER, customer);
  }
};

export default CustomerActions;
