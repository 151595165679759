import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import { hasAccess as accessTo } from "@/utils/has-access.function";
import Home from "@/views/Home.vue";
import store from "@/store";
import { RouteNames, RouteNamesV2 } from "@/router/routeNames";

Vue.use(VueRouter);

const ifNotAuthenticated = (to: Route, from: Route, next: (to?: any) => void) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to: Route, from: Route, next: (to?: any) => void) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

const hasAccessToB2BDashboardFlow = (to: Route, from: Route, next: (to?: any) => void) => {
  if (store.getters.isAuthenticated && accessTo(to.name)) {
    next();
    return;
  }
  next(false);
};

const hasAccessToPetHistoryFlow = (to: Route, from: Route, next: (to?: any) => void) => {
  if (store.getters.isAuthenticated && accessTo(to.name)) {
    next();
    return;
  }
  next(false);
};

const vuetifyViewEnabled = (to: Route, from: Route, next: (to?: any) => void) => {
  if (store.getters.isAuthenticated && store.getters.vetVuetifyViews) {
    next();
    return;
  }
  next(false);
};

const isPrototypeEnabled = (to: Route, from: Route, next: (to?: any) => void) => {
  if (store.getters.vetPrototype) {
    next();
    return;
  }
  next("/");
};

const redirectFromBlendConfiguration = (to: Route, from: Route, next: (to?: any) => void) => {
  if (store.getters.vetVuetifyViews) {
    next();
    return;
  }
  if (to.params.pet_id) {
    next(`/customer/${to.params.customer_id}/pet/${to.params.pet_id}/nutritional-plan`);
    return;
  }
  next(`/customer/${to.params.customer_id}/pet-information/`);
};

const routes = [
  {
    path: "/login",
    name: RouteNames.LOGIN,
    beforeEnter: ifNotAuthenticated,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/Authentication/Login.vue")
  },
  {
    path: "/",
    name: RouteNames.DASHBOARD,
    beforeEnter: ifAuthenticated,
    component: () => import(/* webpackChunkName: "about" */ "../components/Dashboard/Dashboard.vue")
  },
  {
    beforeEnter: ifAuthenticated,
    path: "/pre-customer-details/",
    name: RouteNames.PRE_CUSTOMER_DETAILS,
    component: Home
  },
  {
    beforeEnter: ifAuthenticated,
    path: "/pre-customer-details/:pet_id",
    name: RouteNames.EDIT_CUSTOMER_DETAILS,
    component: Home
  },
  {
    beforeEnter: ifAuthenticated,
    path: "/customer/:customer_id/pet-information/:pet_id?",
    name: RouteNames.PET_INFORMATION,
    component: () => import("../components/PetInformation/PetInformation.vue")
  },
  {
    beforeEnter: ifAuthenticated,
    path: "/pet/:pet_id/urgent-diet/",
    name: RouteNames.URGENT_DIET,
    component: () => import("../components/UrgentDiet/UrgentDiet.vue")
  },
  {
    path: "/pet/:pet_id/feeding-preferences",
    name: RouteNames.FEEDING_PREFERENCES,
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/FeedingPreferences/FeedingPreferences.vue"
      )
  },
  {
    path: "/customer/:customer_id/pet/:pet_id/diet-requirements",
    name: RouteNames.DIET_REQUIREMENTS,
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/PetDietRequirements/PetDietRequirements.vue"
      )
  },
  {
    path: "/customer/:customer_id/pet/:pet_id/nutritional-plan/",
    name: RouteNames.NUTRITIONAL_PLAN,
    beforeEnter: ifAuthenticated,
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/NutritionalPlan/NutritionalPlan.vue"),
    meta: {
      breadcrumbs(route: Route): any {
        return [
          {
            text: "Customer Hub",
            disabled: false,
            to: {
              name: RouteNames.CUSTOMER_HUB,
              params: { customer_id: +route.params.customer_id }
            }
          },
          {
            text: "Blend Configuration",
            disabled: false,
            to: {
              name: RouteNamesV2.BLEND_CONFIGURATION,
              params: {
                customer_id: +route.params.customer_id,
                pet_id: +route.params.pet_id
              }
            }
          },
          {
            text: "Feeding Plan"
          }
        ];
      }
    }
  },
  {
    path: "/customer/:customer_id/pet/:pet_id/portioning",
    name: RouteNames.PORTIONING,
    beforeEnter: ifAuthenticated,
    component: () => import(/* webpackChunkName: "about" */ "../views/EditPortionPage.vue"),
    meta: {
      breadcrumbs(route: Route): any {
        return [
          {
            text: "Customer Hub",
            disabled: false,
            to: {
              name: RouteNames.CUSTOMER_HUB,
              params: { customer_id: +route.params.customer_id }
            }
          },
          {
            text: "Blend Configuration",
            disabled: false,
            to: {
              name: RouteNamesV2.BLEND_CONFIGURATION,
              params: {
                customer_id: +route.params.customer_id,
                pet_id: +route.params.pet_id
              }
            }
          },
          {
            text: "Feeding Plan",
            disabled: false,
            to: {
              name: RouteNames.NUTRITIONAL_PLAN,
              params: {
                customer_id: +route.params.customer_id,
                pet_id: +route.params.pet_id
              }
            }
          },
          {
            text: "Edit Portion"
          }
        ];
      }
    }
  },
  {
    path: "/customer/:customer_id/pet/:pet_id/condition-history",
    name: RouteNames.CONDITION_HISTORY,
    beforeEnter: hasAccessToPetHistoryFlow,
    component: () => import(/* webpackChunkName: "about" */ "../views/ConditionHistory.vue")
  },
  {
    path: "/customer/:customer_id/customer-details",
    name: RouteNames.CUSTOMER_DETAILS,
    beforeEnter: ifAuthenticated,
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/CustomerDetails/CustomerDetails.vue")
  },
  {
    path: "/customer/:customer_id/pet/:pet_id/payment-details",
    name: RouteNames.PAYMENT_DETAILS,
    beforeEnter: ifAuthenticated,
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/PaymentDetails/PaymentDetails.vue")
  },
  {
    path: "/thank-you",
    name: RouteNames.THANK_YOU,
    beforeEnter: ifAuthenticated,
    component: () => import(/* webpackChunkName: "about" */ "../components/GoodbyePage/Goodbye.vue")
  },
  {
    path: "/password-reset",
    name: RouteNames.PASSWORD_RESET,
    component: () =>
      import(/* webpackChunkName: "password-reset" */ "../views/Authentication/SetNewPassword.vue")
  },
  {
    path: "/request-password-reset",
    name: RouteNames.REQUEST_PASSWORD_RESET,
    component: () =>
      import(
        /* webpackChunkName: "request-password-reset" */ "../views/Authentication/RequestPasswordReset.vue"
      )
  },
  {
    path: "/customer/:customer_id/price",
    name: RouteNames.PRICE,
    beforeEnter: ifAuthenticated,
    component: () => import("../views/PricingPage.vue"),
    meta: {
      breadcrumbs(route: Route): any {
        return [
          {
            text: "Customer Hub",
            disabled: false,
            to: {
              name: RouteNames.CUSTOMER_HUB,
              params: { customer_id: +route.params.customer_id }
            }
          },
          {
            text: "Pricing"
          }
        ];
      }
    }
  },
  {
    path: "/customer-hub/:customer_id",
    name: RouteNames.CUSTOMER_HUB,
    beforeEnter: ifAuthenticated,
    component: () => import("../views/CustomerHubPage.vue")
  },
  {
    path: "/performance-dashboard",
    name: RouteNames.PERFORMANCE_DASHBOARD,
    beforeEnter: hasAccessToB2BDashboardFlow,
    component: () => import("../views/PerformanceDashboard.vue")
  },
  {
    path: "/zendesk/sso",
    name: RouteNames.ZENDESK_SSO,
    beforeEnter: ifAuthenticated,
    component: () => import("../views/Authentication/ZendeskSSO.vue")
  },
  {
    path: "/customer/:customer_id/pet/:pet_id/nutritional-plan/fortiflora",
    name: RouteNames.FORTIFLORA,
    beforeEnter: vuetifyViewEnabled,
    component: () => import("../views/v2/Fortiflora.vue"),
    meta: {
      breadcrumbs(route: Route): any {
        return [
          {
            text: "Customer Hub",
            disabled: false,
            to: {
              name: RouteNames.CUSTOMER_HUB,
              params: { customer_id: +route.params.customer_id }
            }
          },
          {
            text: "Blend Configuration",
            disabled: false,
            to: {
              name: RouteNamesV2.BLEND_CONFIGURATION,
              params: {
                customer_id: +route.params.customer_id,
                pet_id: +route.params.pet_id
              }
            }
          },
          {
            text: "Feeding Plan",
            disabled: false,
            exact: true,
            to: {
              name: RouteNames.NUTRITIONAL_PLAN,
              params: {
                customer_id: +route.params.customer_id,
                pet_id: +route.params.pet_id
              }
            }
          },
          {
            text: "Fortiflora"
          }
        ];
      }
    }
  },
  {
    path: "/customer/:customer_id/checkout/",
    name: RouteNames.CHECKOUT,
    component: () => import(/* webpackChunkName: "about" */ "../views/v2/Checkout.vue"),
    meta: {
      breadcrumbs(route: Route): any {
        return [
          {
            text: "Customer Hub",
            disabled: false,
            to: {
              name: RouteNames.CUSTOMER_HUB,
              params: { customer_id: +route.params.customer_id }
            }
          },
          {
            text: "Pricing",
            disabled: false,
            to: {
              name: RouteNames.PRICE,
              params: { customer_id: +route.params.customer_id }
            }
          },
          {
            text: "Checkout"
          }
        ];
      }
    }
  },
  {
    path: "/customer/:customer_id/payment/",
    name: RouteNames.PAYMENT,
    beforeEnter: vuetifyViewEnabled,
    component: () => import(/* webpackChunkName: "about" */ "../views/v2/Payment.vue"),
    meta: {
      breadcrumbs(route: Route): any {
        return [
          {
            text: "Customer Hub",
            disabled: false,
            to: {
              name: RouteNames.CUSTOMER_HUB,
              params: { customer_id: +route.params.customer_id }
            }
          },
          {
            text: "Pricing",
            disabled: false,
            to: {
              name: RouteNames.PRICE,
              params: { customer_id: +route.params.customer_id }
            }
          },
          {
            text: "Checkout",
            disabled: false,
            to: {
              name: RouteNames.CHECKOUT,
              params: {
                customer_id: +route.params.customer_id,
                pet_id: +route.params.pet_id
              }
            }
          },
          {
            text: "Payment"
          }
        ];
      }
    }
  },
  {
    path: "/customer/thank-you",
    name: RouteNames.PAYMENT_THANK_YOU,
    beforeEnter: vuetifyViewEnabled,
    component: () => import(/* webpackChunkName: "about" */ "../views/v2/PaymentThankYou.vue")
  },
  {
    path: "/register",
    name: RouteNamesV2.ROOT,
    redirect: { name: RouteNamesV2.CREATE_CUSTOMER },
    beforeEnter: isPrototypeEnabled,
    component: () => import("../views/v2/Main.vue"),
    children: [
      {
        path: "thank-you",
        name: RouteNamesV2.THANK_YOU,
        component: () => import("../views/v2/ThankYou.vue")
      },
      {
        path: ":practiceGroupId?",
        name: RouteNamesV2.CREATE_CUSTOMER,
        component: () => import("../views/v2/CreateCustomer.vue")
      },
      {
        path: "pet/:petId?",
        name: RouteNamesV2.CREATE_PET,
        component: () => import("../views/v2/CreatePet.vue")
      },
      {
        path: "email-exists",
        name: RouteNamesV2.EMAIL_EXISTS,
        component: () => import("../views/v2/EmailExists.vue")
      },
      {
        path: "pet-selection",
        name: RouteNamesV2.PET_SELECTION,
        component: () => import("../views/v2/PetSelection.vue")
      }
    ]
  },
  {
    path: "/:customer_id/pet/:pet_id?",
    name: RouteNamesV2.BLEND_CONFIGURATION,
    beforeEnter: redirectFromBlendConfiguration,
    component: () => import("../views/v2/BlendConfiguration.vue"),
    meta: {
      breadcrumbs(route: Route): any {
        return [
          {
            text: "Customer Hub",
            disabled: false,
            to: {
              name: RouteNames.CUSTOMER_HUB,
              params: { customer_id: +route.params.customer_id }
            }
          },
          {
            text: "Blend Configuration"
          }
        ];
      }
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
