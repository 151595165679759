export function formatPrice(amount: number): string {
  return `£${(amount / 1000).toFixed(2)}`;
}

export function formatDiscount(amount: number): string {
  return `-£${(amount / 1000).toFixed(2)}`;
}

export function toFixed(value: number | null): string {
  return value ? value.toFixed(2) : "0.0";
}
