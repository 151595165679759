import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiInterface } from "@/api/api.interface";
import {
  UpdatePetRequest,
  UpdatePetResponse,
  CreateCustomerRequest,
  CreateCustomerResponse,
  GetAllBreedsResponse,
  GetAllHealthConditionsResponse,
  GetAllPetBodyConditionsResponse,
  GetPetByIdResponse,
  PaymentClientSecretResponse,
  SaveDietRequirementsRequest,
  SaveDietRequirementsResponse,
  SubmitPaymentTokenResponsePayload,
  CustomerDetailsResponsePayload,
  VetLoginResponse,
  SearchPetsResponse,
  GetNutritionalPlanResponse,
  GetSelectableHealthIssuesQueryParameters,
  GetSelectableHealthIssuesResponse,
  CustomerDetailsRequestPayload,
  VetLogoutResponse,
  UrgentDietResponse,
  UrgentDietPostPayload,
  UrgentDietPostResponsePayload,
  CustomerCurrentPaymentMethodResponse,
  ActivateSubscriptionResponse,
  SetNewPasswordResponse,
  RequestPasswordResetResponse,
  InitOutOfClinicCheckoutResponse,
  CustomerType,
  ApiResponse,
  Flavour,
  FeedingPreferencePayload,
  ApiNoDataResponse,
  ThirdPartyBrandPayload
} from "@/api/api.dto";

const API_ENDPOINTS = {
  CLIENT_SECRET: "/api/vets/v1/customer/payment-method",
  PET: "/api/vets/v1/pet",
  CUSTOMER: "/api/vets/v1/customer",
  GET_ALL_BREEDS: "/api/vets/v1/pet/breeds",
  GET_ALL_BODY_CONDITIONS: "/api/vets/v1/pet/conditions",
  GET_ALL_HEALTH_CONDITIONS: "/api/vets/v1/pet/health-conditions",
  GET_SELECTABLE_HEALTH_ISSUES: "/api/vets/v1/pet/selectable-health-issues"
};

const isBadRequestError = (error: AxiosError) =>
  error && error.response && error.response.status === 400;

export default class ApiProduction implements ApiInterface {
  async updatePet(updatePetPayload: UpdatePetRequest, petId: number) {
    const response = {} as UpdatePetResponse;
    try {
      const axiosResponse = await axios.put(`${API_ENDPOINTS.PET}/${petId}`, updatePetPayload);
      response.success = true;
      response.pet_id = axiosResponse.data.resp.pet_id;
    } catch (error) {
      response.success = false;
      response.errorMessage = "Could not update pet";
      response.errors = error.response.data.resp.errors;
    }
    return response;
  }
  async createCustomer(
    createCustomerRequest: CreateCustomerRequest
  ): Promise<CreateCustomerResponse> {
    const response = {} as CreateCustomerResponse;
    try {
      const axiosResponse = await axios.post("/api/vets/v1/pre-customer", createCustomerRequest);
      response.success = true;
      response.customer_id = axiosResponse.data.resp.new_customer.customer_id;
    } catch (error) {
      response.success = false;
      if (isBadRequestError(error)) {
        response.errors = error.response.data.resp.errors;
      }
    }
    return response;
  }

  async getAllBreeds(): Promise<GetAllBreedsResponse> {
    const response = {} as GetAllBreedsResponse;
    try {
      const axiosResponse: AxiosResponse = await axios.get(API_ENDPOINTS.GET_ALL_BREEDS);
      response.breeds = axiosResponse.data.breeds;
      response.success = true;
    } catch (error) {
      response.success = false;
    }
    return response;
  }

  async getAllHealthIssues(): Promise<GetAllHealthConditionsResponse> {
    const response = {} as GetAllHealthConditionsResponse;
    try {
      const axiosResponse: AxiosResponse = await axios.get(API_ENDPOINTS.GET_ALL_HEALTH_CONDITIONS);
      response.healthIssues = axiosResponse.data.health_conditions;
      response.success = true;
    } catch (error) {
      response.success = false;
    }
    return response;
  }

  async getAllPetBodyConditions(): Promise<GetAllPetBodyConditionsResponse> {
    const response = {} as GetAllPetBodyConditionsResponse;
    try {
      const axiosResponse: AxiosResponse = await axios.get(API_ENDPOINTS.GET_ALL_BODY_CONDITIONS);
      response.conditions = axiosResponse.data.resp.conditions;
      response.success = true;
    } catch (error) {
      response.success = false;
    }
    return response;
  }

  async getPetById(petId: number): Promise<GetPetByIdResponse> {
    const petDetails = {
      success: false
    } as GetPetByIdResponse;
    try {
      const response = await axios.get(`${API_ENDPOINTS.PET}/${petId}`);
      petDetails.success = true;
      petDetails.petDetails = response.data.resp;
    } catch (error) {
      petDetails.errorMessage = "Could not get pet details";
    }
    return petDetails;
  }

  async saveDietRequirements(
    dietRequirements: SaveDietRequirementsRequest
  ): Promise<SaveDietRequirementsResponse> {
    const response = {} as UpdatePetResponse;
    const UPDATE_DIET_ENDPOINT = `${API_ENDPOINTS.PET}/${dietRequirements.pet_id}/selectable-health-issues`;
    try {
      await axios.post(UPDATE_DIET_ENDPOINT, {
        diet_type: dietRequirements.diet_type,
        primary_health_issue_id: dietRequirements.primary_health_issue_id,
        secondary_health_issue_id: dietRequirements.secondary_health_issue_id,
        tertiary_health_issue_id: dietRequirements.tertiary_health_issue_id
      });
      response.success = true;
    } catch (error) {
      response.success = false;
      response.errorMessage = "Could not save diet requirements";
    }
    return response;
  }

  async getCustomerDetails(petId: number): Promise<CustomerDetailsResponsePayload> {
    const response = {
      success: false
    } as CustomerDetailsResponsePayload;
    try {
      const axiosResponse = await axios.get(`${API_ENDPOINTS.PET}/${petId}/customer`);
      response.customer = axiosResponse.data.resp;
      response.success = !!response.customer;
    } catch (error) {
      response.errorMessage = "Could not retrieve customer details";
    }
    return response;
  }

  async updateCustomer(
    customerId: number,
    updateCustomerRequest: CustomerDetailsRequestPayload
  ): Promise<CustomerDetailsResponsePayload> {
    const response = {
      success: false
    } as CustomerDetailsResponsePayload;
    try {
      const axiosResponse = await axios.put(
        `${API_ENDPOINTS.CUSTOMER}/${customerId}`,
        updateCustomerRequest
      );
      response.success = true;
      response.customer = axiosResponse.data.resp;
    } catch (error) {
      response.success = false;
      if (isBadRequestError(error)) {
        response.errors = error.response ? error.response.data.resp.errors : "";
      } else {
        response.errorMessage = "Could not update customer";
      }
    }
    return response;
  }

  async updatePreCustomer(
    updateCustomerRequest: CustomerDetailsRequestPayload,
    customerId: number
  ): Promise<CustomerDetailsResponsePayload> {
    const response = {
      success: false
    } as CustomerDetailsResponsePayload;
    try {
      const axiosResponse = await axios.put(
        `/api/vets/v1/pre-customer/${customerId}`,
        updateCustomerRequest
      );
      response.success = true;
      response.customer = axiosResponse.data.resp;
    } catch (error) {
      response.success = false;
      if (isBadRequestError(error)) {
        response.errors = error.response ? error.response.data.resp.errors : "";
      } else {
        response.errorMessage = "Could not update customer";
      }
    }
    return response;
  }

  async vetLogin(username: string, password: string): Promise<VetLoginResponse> {
    const response = { success: false } as VetLoginResponse;
    try {
      const axiosResponse = await axios.post("/api/vets/v1/login", { username, password });
      const data = axiosResponse.data.resp;
      response.apitoken = data.api_token;
      response.vet_practice_group_name = data.vet_practice_group_name;
      response.vet_name = data.vet_name;
      response.authenticated = data.authenticated;
      response.success = true;
      return response;
    } catch (error) {
      const data = error.response.data.resp;
      if (error.response.status === 401) {
        response.errorMessage = data.error;
      } else {
        response.errorMessage =
          "There was a problem logging in. Please try again later or contact support if the error persists.";
      }
      response.success = false;
    }
    return response;
  }

  async vetLogout(): Promise<VetLogoutResponse> {
    const response = { success: false } as VetLoginResponse;
    try {
      await axios.post("/api/vets/v1/logout");
      response.success = true;
      return response;
    } catch (error) {
      response.success = false;
      response.errorMessage = error;
    }
    return response;
  }

  async submitPaymentToken(
    token: string,
    customerId: number,
    petId: number
  ): Promise<SubmitPaymentTokenResponsePayload> {
    const response = { success: false } as SubmitPaymentTokenResponsePayload;
    try {
      await axios.post(`${API_ENDPOINTS.CUSTOMER}/${customerId}/payment-method`, {
        stripe_token: token,
        pet_id: petId
      });
      response.success = true;
    } catch (errors) {
      response.errorMessage = errors.message;
    }
    return response;
  }

  async fetchPaymentClientSecret(): Promise<PaymentClientSecretResponse> {
    const response = { success: false } as PaymentClientSecretResponse;
    try {
      const axiosResponse = await axios.get(API_ENDPOINTS.CLIENT_SECRET);
      response.success = true;
      response.clientSecret = axiosResponse.data.resp.client_key;
    } catch (error) {
      response.errorMessage = error.message;
    }
    return response;
  }

  async searchPets(query: string, showInactive = false): Promise<SearchPetsResponse> {
    const response = { success: false } as SearchPetsResponse;
    const encodedQuery = encodeURIComponent(query);

    try {
      let url = `${API_ENDPOINTS.PET}/?search_text=${encodedQuery}`;
      if (showInactive) {
        url += "&with_inactive=1";
      }
      // Limit to 6 if the query is empty, which indicates we should show recent pets instead
      if (query.length === 0) {
        url += "&limit=6";
      }
      const axiosResponse = await axios.get(url);
      response.success = true;
      response.resp = axiosResponse.data.resp;
    } catch (error) {
      response.errorMessage = "Error trying to search pets";
    }
    return response;
  }

  async getNutritionalPlan(petId: number): Promise<GetNutritionalPlanResponse> {
    const nutritionalPlan = { success: false } as GetNutritionalPlanResponse;
    try {
      const axiosResponse = await axios.get(`${API_ENDPOINTS.PET}/${petId}/nutritional-plan`);
      nutritionalPlan.success = true;
      nutritionalPlan.resp = axiosResponse.data.resp;
    } catch (error) {
      nutritionalPlan.errorMessage = error.message;
    }
    return nutritionalPlan;
  }

  async getSelectableHealthIssues(
    getSelectableHealthIssuesQueryParams: GetSelectableHealthIssuesQueryParameters
  ): Promise<GetSelectableHealthIssuesResponse> {
    const response = { success: false } as GetSelectableHealthIssuesResponse;

    try {
      const axiosResponse = await axios.get(API_ENDPOINTS.GET_SELECTABLE_HEALTH_ISSUES, {
        params: getSelectableHealthIssuesQueryParams
      });
      response.success = true;
      response.resp = axiosResponse.data.resp;
    } catch (error) {
      response.errorMessage = error.message;
    }

    return response;
  }

  async getUrgentDiet(petId: number): Promise<UrgentDietResponse> {
    const response = { success: false } as UrgentDietResponse;

    try {
      const axiosResponse = await axios.get(`${API_ENDPOINTS.PET}/${petId}/urgent-diet`);
      response.success = true;
      response.resp = axiosResponse.data.resp;
    } catch (error) {
      response.success = false;
      if (!error.isAxiosError) {
        response.errors = error.response ? error.response.data.resp.errors : "";
      } else {
        response.errors = error;
      }
    }
    return response;
  }

  async createUrgentDiet(
    petId: number,
    urgentHealthIssueId: number,
    numberOfDays: number
  ): Promise<UrgentDietPostResponsePayload> {
    const response = { success: false, errors: {} } as UrgentDietPostResponsePayload;

    try {
      const axiosResponse = await axios.post(`${API_ENDPOINTS.PET}/${petId}/urgent-diet`, {
        urgent_health_issue_id: urgentHealthIssueId,
        number_of_days: numberOfDays
      } as UrgentDietPostPayload);
      response.success = true;
      response.resp = axiosResponse.data.resp;
    } catch (error) {
      response.success = false;
      response.errorMessage = "Could not save urgent diet details";
    }
    return response;
  }

  async getCurrentPaymentMethod(petId: number): Promise<CustomerCurrentPaymentMethodResponse> {
    const response = { success: false } as CustomerCurrentPaymentMethodResponse;

    try {
      const axiosResponse = await axios.get(`${API_ENDPOINTS.CUSTOMER}/current-payment-method`, {
        params: { pet_id: petId }
      });
      response.success = true;
      response.resp = axiosResponse.data.resp;
    } catch (error) {
      response.success = false;
      if (error.isAxiosError) {
        response.errors = error.response ? error.response.data.resp.errors : "";
      } else {
        response.errors = error;
      }
    }
    return response;
  }

  async activateSubscription(petId: number): Promise<ActivateSubscriptionResponse> {
    const response = { success: false } as ActivateSubscriptionResponse;
    try {
      await axios.put(`${API_ENDPOINTS.PET}/${petId}/activate-subscription`);
      response.success = true;
    } catch (error) {
      response.errorMessage = "Could not activate subscription";
    }
    return response;
  }

  async reactivateSubscription(petId: number): Promise<ActivateSubscriptionResponse> {
    const response = { success: false } as ActivateSubscriptionResponse;
    try {
      await axios.put(`${API_ENDPOINTS.PET}/${petId}/reactivate-subscription`);
      response.success = true;
    } catch (error) {
      response.errorMessage = "Could not reactivate subscription";
    }
    return response;
  }

  async setNewPassword(newPassword: string, token: string): Promise<SetNewPasswordResponse> {
    const defaultErrorMessage =
      "There was an error trying to reset your password. Try again or contact support.";
    const EXPIRED_TOKEN_CODE = 403;
    const response = { success: false } as SetNewPasswordResponse;
    try {
      await axios.put("/api/vets/v1/password-reset", {
        password: newPassword,
        token
      });
      response.success = true;
    } catch (error) {
      if (error.response && error.response.status === EXPIRED_TOKEN_CODE) {
        response.errorMessage = "The link used has expired.";
        response.expiredToken = true;
      } else {
        response.errorMessage = defaultErrorMessage;
      }
    }
    return response;
  }

  async requestPasswordReset(email: string): Promise<RequestPasswordResetResponse> {
    const response = { success: false } as RequestPasswordResetResponse;
    try {
      await axios.post("/api/vets/v1/password-reset", { email });
      response.success = true;
    } catch (error) {
      response.errorMessage = "Could not request password reset";
    }
    return response;
  }

  async initOutOfClinicCheckout(petId: number): Promise<InitOutOfClinicCheckoutResponse> {
    const response = { success: false } as InitOutOfClinicCheckoutResponse;
    try {
      await axios.post(`${API_ENDPOINTS.CUSTOMER}/init-out-of-clinic-checkout`, {
        pet_id: petId
      });
      response.success = true;
    } catch (error) {
      response.errorMessage = "Could not initialize out of clinic checkout";
    }
    return response;
  }

  getCustomerTypes(): Promise<Array<CustomerType>> {
    return axios.get("/api/customer/customer-types").then(result => result.data.resp);
  }

  async getFlavours(): Promise<ApiResponse<Flavour[]>> {
    try {
      const resp = await axios.get(`${API_ENDPOINTS.PET}/flavours`);
      return { success: true, resp: resp.data.resp } as ApiResponse<Flavour[]>;
    } catch (error) {
      return { success: false, errorMessage: "Can get flavours" } as ApiResponse<Flavour[]>;
    }
  }

  async getFeedingPreferences(petId: number): Promise<ApiResponse<FeedingPreferencePayload>> {
    try {
      const resp = await axios.get(`${API_ENDPOINTS.PET}/${petId}/feeding-preferences`);
      return { success: true, resp: resp.data.resp } as ApiResponse<FeedingPreferencePayload>;
    } catch (error) {
      return { success: false, errorMessage: "Can get flavours" } as ApiResponse<
        FeedingPreferencePayload
      >;
    }
  }

  async getThirdPartyBrands(): Promise<ApiResponse<ThirdPartyBrandPayload>> {
    try {
      const resp = await axios.get(`/api/vets/v1/catalogue/third-party-brands/`);
      return { success: true, resp: resp.data.resp } as ApiResponse<ThirdPartyBrandPayload>;
    } catch (error) {
      return { success: false, errorMessage: "Can get flavours" } as ApiResponse<
        ThirdPartyBrandPayload
      >;
    }
  }

  async assignFeedingPreferences(
    petId: number,
    flavours: string[],
    brands: string[]
  ): Promise<ApiNoDataResponse> {
    try {
      await axios.post(`${API_ENDPOINTS.PET}/${petId}/feeding-preferences`, { flavours, brands });
      return { success: true } as ApiNoDataResponse;
    } catch (error) {
      return { success: false, errorMessage: "Can assign flavours" } as ApiNoDataResponse;
    }
  }
}
