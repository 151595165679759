import { CreateCustomerRequest } from "@/api/api.dto";

export interface CustomerFormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  dataCaptureConsent?: boolean;
  petHealthClubMember: boolean;
  contactConsent: string | null;
}

export function mapToCreateCustomerRequest(
  customerFormData: CustomerFormData
): CreateCustomerRequest {
  try {
    return {
      first_name: customerFormData.firstName,
      last_name: customerFormData.lastName,
      mobile_phone: customerFormData.phoneNumber,
      email: customerFormData.email,
      data_capture_consent: customerFormData.dataCaptureConsent,
      pet_health_club_member: customerFormData.petHealthClubMember,
      contact_consent: customerFormData.contactConsent === "yes"
    } as CreateCustomerRequest;
  } catch (error) {
    return {} as CreateCustomerRequest;
  }
}
