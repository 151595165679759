import { ConsultationModes } from "@/components/Dashboard/Dashboard.vm";
import store, { ACTIONS } from "@/store";

export class LocalStorageProduction {
  CUSTOMER_ID = "customerId";

  saveConsultationMode(consultationMode: ConsultationModes) {
    store.dispatch(ACTIONS.SET_CONSULTATION_MODE, consultationMode);
  }

  clearConsultationMode(): void {
    store.dispatch(ACTIONS.SET_CONSULTATION_MODE, null);
  }

  getConsultationMode(): ConsultationModes {
    const consultationMode = store.state.consultationMode;
    if (consultationMode === ConsultationModes.VIEW_OR_EDIT_ACTIVE) {
      return ConsultationModes.VIEW_OR_EDIT_ACTIVE;
    }
    return ConsultationModes.NONE;
  }
}
