import { AccessInterface, Role, Token } from "@/store/auth/auth.types";

export enum AuthStatus {
  none,
  loading,
  success,
  error
}

export default interface AuthState {
  vetName: string;
  authToken: Token;
  practiceGroupName: string;
  authStatus: AuthStatus;
  errorMessage: string;
  role: Role;
  accesses: AccessInterface | null;
}
