<template>
  <WithOldVetBar>
    <div class="home">
      <PreCustomerDetails />
    </div>
  </WithOldVetBar>
</template>

<script>
import PreCustomerDetails from "@/components/PreCustomerDetails/PreCustomerDetails.vue";
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";

export default {
  name: "home",
  components: {
    WithOldVetBar,
    PreCustomerDetails
  }
};
</script>
