<script>
import Vue from "vue";

export default Vue.extend({
  name: "LegalDisclaimer",
  data() {
    return {};
  }
});
</script>

<template>
  <div data-cy="legal-disclaimer" class="legal-disclaimer">
    <div>
      <h2 class="brand-h2">Personalised Nutrition &ndash; Client consent</h2>
      <p>
        Your pet is about to have a nutrition module in order to provide you and your dog a proposal
        for a Personalised Nutrition Plan. This is part of a pilot scheme, the first of its kind in
        the UK, which is a collaboration between the following pet related companies: IVC Evidenisa,
        Nestl&eacute; Purina UK Commercial Operations Limited, IDEXX Europe B.V. and Tailsco ltd.
      </p>
      <p>
        In order to fulfil this service and follow up on any client queries it is essential that the
        named above organisations share data surrounding your pet, your pet’s diet, your pet’s blood
        test results and your address details. This will only be used in relation to the
        Personalised Nutrition Plan, should you choose to enrol, and it will not be provided to any
        3rd parties for marketing purposes. Should you not wish to enrol immediately into the
        Personalised Nutrition Plan your data will be deleted after 4 months. Should you definitely
        not wish to enrol into the Personalised Nutrition Plan, please inform your local Vet
        Practice staff that you would like to withdraw your consent in order to delete your data
        related to the Personalised Nutrition Plan consultation. Please be aware that if you inform
        us that you don’t want to enrol you will have to proceed from the beginning to be able to
        access the services.
      </p>
      <p>
        Please note payment details data is only held by Tailsco via their secure payment provider.
      </p>
      <p>
        Under the General Data Protection Regulation 2016 (GDPR) you are entitled to withdraw
        consent at any time. If you wish to do so please email
        <a href="mailto:contact@personalisedproplan.co.uk">contact@personalisedproplan.co.uk</a>, or
        call <a href="tel:0800212161">0800 21 21 61</a>.
      </p>
      <p>
        You can find more information about how your data is managed in the privacy policy on your
        vet practices’ website.
      </p>
      <p>
        By accepting you are giving consent to the data to be shared in the way as outlined above.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped="scoped">
.legal-disclaimer {
  background-color: #f8f9fa;
  width: 100%;
  padding: 0px 20px 10px 20px;
}
</style>
