import axios, { AxiosResponse } from "axios";

export type Feature = {
  is_enabled: boolean;
};

export class FeatureFlags {
  static async isEnabled(flagName: string): Promise<boolean> {
    const url = `/api/feature_flag/is_enabled/${flagName}`;
    try {
      const featureFlagResponse: AxiosResponse = await axios.get(url);
      const response: Feature = { ...{ is_enabled: false }, ...featureFlagResponse.data.resp };
      return response.is_enabled;
    } catch (error) {
      return false;
    }
  }
}
