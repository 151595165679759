import Vue from "vue";
import Vuex from "vuex";
import petStore from "@/store/pet/pet.store";
import customerStore from "@/store/customer/customer.store";
import { ConsultationModes } from "@/components/Dashboard/Dashboard.vm";
import featureFlagStore from "@/store/feature-flag/feature-flag.store";
import authStore from "@/store/auth/auth.store";

Vue.use(Vuex);

// Actions
export const ACTIONS = {
  SET_PET_DETAILS: "set-pet-details",
  SET_FEEDING_PLAN: "set-feeding-plan",
  SET_CONSULTATION_MODE: "set-consultation-mode",
  SET_PROFILE: "profile"
};

// Mutations
const MUTATIONS = {
  SET_PET_DETAILS: "SET_PET_DETAILS",
  SET_FEEDING_PLAN: "SET_FEEDING_PLAN",
  SET_CONSULTATION_MODE: "SET_CONSULTATION_MODE"
};

export type RootState = {
  petDetails: any;
  feedingPlan: any;
  petStore: any;
  consultationMode: ConsultationModes | null;
};

export default new Vuex.Store<RootState>({
  state: {
    petDetails: null,
    feedingPlan: null,
    consultationMode: null
  } as RootState,
  mutations: {
    [MUTATIONS.SET_FEEDING_PLAN]: (state: RootState, feedingPlan: any) => {
      state.feedingPlan = feedingPlan;
    },
    [MUTATIONS.SET_PET_DETAILS]: (state: RootState, petDetails: any) => {
      state.petDetails = petDetails;
    },
    [MUTATIONS.SET_CONSULTATION_MODE]: (state: RootState, consultationMode: ConsultationModes) => {
      state.consultationMode = consultationMode;
    }
  },
  actions: {
    [ACTIONS.SET_PET_DETAILS]: ({ commit }, petDetails) => {
      commit(MUTATIONS.SET_PET_DETAILS, petDetails);
    },
    [ACTIONS.SET_FEEDING_PLAN]: ({ commit }, feedingPlan) => {
      commit(MUTATIONS.SET_FEEDING_PLAN, feedingPlan);
    },
    [ACTIONS.SET_CONSULTATION_MODE]: ({ commit }, consultationMode: ConsultationModes) => {
      commit(MUTATIONS.SET_CONSULTATION_MODE, consultationMode);
    }
  },
  modules: {
    authStore: authStore,
    petStore: petStore,
    customerStore: customerStore,
    featureFlagStore: featureFlagStore
  },

  getters: {
    originalDailyKcalRequirement: (state: RootState) => state.feedingPlan?.originalTargetKcal,
    totalDailyKcalRequirement: (state: RootState) => state.feedingPlan?.totalDailyKcalRequirement
  }
});
