import PetMutations from "@/store/pet/pet.mutations";
import PetActions from "@/store/pet/pet.actions";
import PetState from "@/store/pet/pet.state";

const petStore = {
  state: {
    pet: null
  } as PetState,
  mutations: PetMutations,
  actions: PetActions
};

export default petStore;
