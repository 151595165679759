import FeatureFlagState from "@/store/feature-flag/feature-flag.state";

export enum FeatureFlagMutationsNames {
  ZENDESK_SSO_FLAG = "ZENDESK_SSO_FLAG",
  VET_PROTOTYPE_FLAG = "VET_PROTOTYPE_FLAG",
  VET_VUETIFY_VIEWS = "VET_VUETIFY_VIEWS"
}

const FeatureFlagMutations = {
  [FeatureFlagMutationsNames.ZENDESK_SSO_FLAG](state: FeatureFlagState, zendeskSSOFlag: any): void {
    state.zendeskFlag = zendeskSSOFlag;
  },
  [FeatureFlagMutationsNames.VET_PROTOTYPE_FLAG](state: FeatureFlagState, vetPrototypeFlag: any) {
    state.vetPrototypeFlag = vetPrototypeFlag;
  },
  [FeatureFlagMutationsNames.VET_VUETIFY_VIEWS](state: FeatureFlagState, vetVuetifyViewsFlag: any) {
    state.vetVuetifyViewsFlag = vetVuetifyViewsFlag;
  }
};

export default FeatureFlagMutations;
