import { Commit } from "vuex";
import { PetMutationsNames } from "@/store/pet/pet.mutations";
import { Pet } from "@/models/pet.model";

export interface VuexCommit {
  commit: Commit;
}

export enum PetActionsNames {
  SET_PET = "SET_PET"
}

const PetActions = {
  [PetActionsNames.SET_PET]: ({ commit }: VuexCommit, pet: Pet) => {
    commit(PetMutationsNames.SET_PET, pet);
  }
};

export default PetActions;
