import FeatureFlagState from "@/store/feature-flag/feature-flag.state";
import FeatureFlagGetters from "@/store/feature-flag/feature-flag.getters";
import FeatureFlagMutations from "@/store/feature-flag/feature-flag.mutations";
import FeatureFlagActions from "@/store/feature-flag/feature-flag.actions";

const featureFlagStore = {
  state: {
    vetPrototypeFlag: false
  } as FeatureFlagState,
  getters: FeatureFlagGetters,
  mutations: FeatureFlagMutations,
  actions: FeatureFlagActions
};

export default featureFlagStore;
