import axios from "axios";
import Cookies from "js-cookie";

interface ZendeskJwtTokenResponse {
  token: string;
}

export const ZendeskCookieID = "zendesk-jwt-token";

export default class ZendeskService {
  public static getZendeskJwtToken(): Promise<ZendeskJwtTokenResponse> {
    return axios
      .get("/api/vets/v1/users/zendesk-jwt")
      .then(response => response.data.resp as ZendeskJwtTokenResponse);
  }

  public static async setup(): Promise<void> {
    const zendeskToken = await ZendeskService.getZendeskJwtToken();
    Cookies.set(ZendeskCookieID, zendeskToken.token);
  }

  public static destroy(): void {
    Cookies.remove(ZendeskCookieID);
  }
}
