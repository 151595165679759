import CustomerMutations from "@/store/customer/customer.mutations";
import CustomerActions from "@/store/customer/customer.actions";
import CustomerState from "@/store/customer/customer.state";

const customerStore = {
  state: {
    customer: null
  } as CustomerState,
  mutations: CustomerMutations,
  actions: CustomerActions
};

export default customerStore;
